import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import enCommon from './locales/en/common';
import enTranslation from './locales/en/translation';
const resources = {
    en: {
        common: enCommon,
        translation: enTranslation,
    },
};
export const GetAvailableLanguages = (t) => ({
    en: t('languages.en', { ns: 'common' }),
    ru: t('languages.ru', { ns: 'common' }),
});
export const numberToWord = (n, t) => t(
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
`numbers.${n}`, {
    ns: 'common',
    defaultValue: n.toString()
}).toString();
const i18nInstance = () => {
    var _a, _b;
    const applicationState = localStorage.getItem('persist:app');
    const defaultLanguage = applicationState ?
        (_b = JSON.parse((_a = JSON.parse(applicationState)) === null || _a === void 0 ? void 0 : _a.app)) === null || _b === void 0 ? void 0 : _b.language :
        undefined;
    return i18n
        .use(initReactI18next)
        .use(resourcesToBackend((language, namespace) => import(`./locales/${language}/${namespace}.ts`)))
        .init({
        resources,
        lng: defaultLanguage || 'en',
        fallbackLng: 'en',
        defaultNS: 'translation',
        ns: ['common', 'translation'],
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        partialBundledLanguages: true,
    });
};
export default i18nInstance();
